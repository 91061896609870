<template>
  <div class="pointes-list">
    <PointCard
      v-for="item in pointLogs"
      :key="item.id"
      :item="item"
      isRedeemed
    ></PointCard>
    <PointPagination v-model="page" :totalPage="totalPage"></PointPagination>
  </div>
</template>

<script>
import PointCard from "@/pages/Dashboard/Point/PointCard";
import PointPagination from "@/pages/Liff/Point/PointPagination";
import moment from "moment";
import pointApi from '@/apis/liff/v2/point'

export default {
  components: {
    PointCard,
    PointPagination,
  },
  props: {
    eventCode: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      pointLogs: [],
      page: 1,
      totalPage: 10,
    };
  },
  mounted() {
    this.fetchRedeemLogs()
  },
  watch: {
    page() {
      this.fetchRedeemLogs()
    }
  },
  methods: {
    async fetchRedeemLogs() {
      let res = await pointApi.listRedeemLogs(this.eventCode, this.page)
      this.totalPage = res.meta.last_page;
      let point_logs = res.data;

      if (!point_logs || !point_logs.length) {
        this.pointLogs = [];
        return;
      }

      this.pointLogs = point_logs.map((pl) => ({
        id: pl.id,
        name: pl.gift?.name,
        secondTitle: pl.gift?.sub_title || "",
        point: pl.point_need,
        date: moment(pl.created_at).format("YYYY/MM/DD"),
        image: pl.gift?.image_url,
        status: "redeemed",
      }));
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
